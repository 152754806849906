.payment-methods {
    margin-top: 40px;
    text-align: center;
  }
  
  .payment-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .payment-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .payment-icon p {
    margin-top: 10px;
    font-size: 14px;
  }
  
  .paggo-info {
    text-align: left;
    margin-top: 20px;
  }